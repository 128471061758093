import clsx from "clsx";
import dayjs from "dayjs";
import { navigate } from "gatsby";

import truncate from "@/base/helpers/truncateString";
import ClipPath from "@/components/elements/ClipPath";
import Heading from "@/components/elements/Heading";
import Image from "@/components/elements/Image";
import ImagePlaceholder from "@/components/elements/ImagePlaceholder";
import Tag from "@/components/elements/Tag";

import Icon from "../Icon";
import * as styles from "./styles.module.scss";
import NewsletterCard from "./NewsletterCard";
import { useLanguageContext } from "@/base/context/LanguageContext";

export function HorizontalCard({
  heroImage,
  publishedDate,
  title,
  excerpt,
  categories,
  url = "/",
  labels,
  badge,
  className,
  getNameByValue,
  ctaLabel,
  isNewsletterCardDisplayed = false,
}: HorizontalCardDataType) {
  const { t } = useLanguageContext();

  return (
    <div
      className={clsx(styles.cardWrapper, className)}
      data-is-newsletter-card-displayed={String(isNewsletterCardDisplayed)}
    >
      <div 
        className={styles.imageWrapper}
        onClick={(e) => {
          e.stopPropagation();
          navigate(url);
        }}
        role="link"  
      >
        <div className={styles.labels}>
          {Array.isArray(labels) &&
            labels.map((label) => (
              <Tag
                type={label}
                key={label}
                size="small"
                className={styles.verticalCardTag}
              />
            ))}
        </div>

        {heroImage?.image ? (
          <Image data={heroImage} fluid className={styles.heroImage} />
        ) : typeof heroImage === "string" ? (
          <Image data={heroImage} alt="card img" className={styles.heroImage} />
        ) : heroImage?.filename ? (
          <img src={heroImage?.filename} alt="" className={styles.heroImage} />
        ) : (
          <ImagePlaceholder />
        )}
      </div>

      <div
        className={styles.contentWrapper}
        onClick={(e) => {
          e.stopPropagation();
          navigate(url);
        }}
        role="link"  
      >
        {publishedDate && (
          <span className={styles.date}>
            {dayjs(publishedDate).format("DD MMMM YYYY")}
          </span>
        )}

        {badge && <span className={styles.badge}>{badge}</span>}

        <div className={styles.content}>
          <Heading level={3}>{title}</Heading>
          {excerpt && <p>{truncate(excerpt, 26)}</p>}
        </div>

        {categories && categories.length > 0 && (
          <div className={styles.categories}>
            {categories?.map((category) => (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/updates/${category}`);
                }}
                key={category}
              >
                <Tag
                  withHover
                  text={getNameByValue && getNameByValue(category)}
                  size="small"
                  className={styles.tag}
                />
              </button>
            ))}
          </div>
        )}
        <p className={styles.readMore}>
          {ctaLabel || t`read-more`} <Icon name="arrowUpRight" />
        </p>
      </div>

      {isNewsletterCardDisplayed && (
        <div className={styles.newsletterCardWrapper}>
          <NewsletterCard />
        </div>
      )}
    </div>
  );
}
