import greyBg from "@/assets/images/grey-bg.png";
import useDataSource from "@/base/hooks/useDataSource";
import Button from "@/components/elements/Button";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import VerticalCard from "@/components/elements/VerticalCard";

import * as listStyles from "@/components/sections/ScientificUpdateList/styles.module.scss";
import { useLanguageContext } from "@/base/context/LanguageContext";
import * as styles from "./styles.module.scss";

interface ScientificUpdateCategoryListProps {
  list?: (UpdatePageTemplate & { full_slug: string })[];
  category?: string;
  categoryId?: string;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  totalAmount: React.MutableRefObject<number>;
  amountPerPage: number;
  tag: string;
}

function ScientificUpdateCategoryList({
  list = [],
  category,
  categoryId,
  setPage,
  page,
  totalAmount,
  amountPerPage,
  tag,
}: ScientificUpdateCategoryListProps) {
  const { getNameByValue } = useDataSource<CategoryType>("categories");

  const totalPages = Math.ceil(totalAmount.current / amountPerPage);
  const itemsExists = page < totalPages;

  const { t, language_code } = useLanguageContext();

  const formatCategory = category || t([`resources.${categoryId}`]);

  return (
    <section className={styles.wrapper}>
      <div className="container">
        <div className={styles.headingContent}>
          {!["befr", "benl"].includes(language_code) && (
            <LinkComponent type="internal" url={`/${language_code}/${tag}`}>
              {tag} /
            </LinkComponent>
          )}
          <Heading level={1}>
            {t`updates.tag`}: “{formatCategory}”
          </Heading>
        </div>
        <div className={listStyles.updatesList}>
          {list.map((scientificUpdate) => (
            <VerticalCard
              key={scientificUpdate._uid}
              title={scientificUpdate.su_title}
              publishedDate={scientificUpdate.su_date_of_publication}
              heroImage={scientificUpdate.su_hero_img}
              excerpt={scientificUpdate.su_excerpt}
              labels={scientificUpdate.su_labels}
              categories={scientificUpdate.su_categories}
              url={scientificUpdate.full_slug}
              getNameByValue={getNameByValue}
            />
          ))}
        </div>
      </div>

      {itemsExists && (
        <Button
          variant="primary"
          onClick={() => setPage((prevPage) => prevPage + 1)}
          className={styles.buttonGetMore}
        >
          {/* TODO: translation */}
          Get more
        </Button>
      )}

      {/* <img src={greyBg} alt="Grey background" className={styles.greyBg} /> */}
    </section>
  );
}

export default ScientificUpdateCategoryList;
