import Button from "@/components/elements/Button";
import { useLanguageContext } from "@/base/context/LanguageContext";
import * as styles from "./styles.module.scss";
import { navigate } from "gatsby";

export function NewsletterCard() {
  const { t, language_code } = useLanguageContext();

  return (
    <div className={styles.newsletterCard}>
      <h2 className={styles.title}>{t`newsletter.card.title`}</h2>

      <p className={styles.description}>{t`newsletter.card.description`}</p>

      <Button 
        variant="primary"
        className={styles.cta}
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/${language_code}`);
        }}
        role="link"  
      >
        {t`newsletter.card.cta`}
      </Button>
    </div>
  );
}
