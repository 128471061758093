// extracted by mini-css-extract-plugin
export var badge = "styles-module--badge--887cf";
export var cardWrapper = "styles-module--card-wrapper--8059e";
export var categories = "styles-module--categories--b6fbb";
export var clipPath = "styles-module--clip-path--5837f";
export var content = "styles-module--content--202f5";
export var contentWrapper = "styles-module--contentWrapper--8a7d3";
export var date = "styles-module--date--f72ec";
export var heroImage = "styles-module--hero-image--c6794";
export var imageWrapper = "styles-module--image-wrapper--2cebe";
export var labels = "styles-module--labels--40a07";
export var link = "styles-module--link--9deb8";
export var newsletterCardWrapper = "styles-module--newsletterCardWrapper--2fa6a";
export var readMore = "styles-module--read-more--35479";
export var tag = "styles-module--tag--457fd";
export var verticalCardTag = "styles-module--vertical-card-tag--79a67";